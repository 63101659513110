import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import cn from 'classnames'

import Page from '../../components/Page'
import SEO from '../../components/SEO'
import Schema from '../../components/Schema'
import LeadSpot from '../../components/solutions/LeadSpot'
import Newsletter from '../../components/Newsletter'

import styles from '../../scss/pages/know-your-food.module.scss'
import LeadStreamer from '../../components/primary/LeadStreamer'
import ExpandableImage from '../../components/ExpandableImage'
import DemoVideo from '../../components/primary/DemoVideo'
import CallToAction from '../../components/primary/CallToAction'

import { isEven } from '../../utils/num'

const KnowYourFoodPage = () => {
  const {
    markdownRemark,
    recipeCardsImage,
  } = useStaticQuery(graphql`
    query KnowYourFoodPageQuery {
      markdownRemark(frontmatter: { path: { eq: "/know-your-food" } }) {
        frontmatter {
          title
          description
          path
          pageIntro {
            ...PageIntroFragment
          }
          benefitsCustomers {
            title
            blurbs {
              title
              text
              icon
            }
          }
          lead {
            ...LeadSpotFragment
            ...LeadStreamerFragment
          }
          section3 {
            title
            text
            image {
              modal: childImageSharp {
                ...SharpImageModalFragment
              }
              childImageSharp {
                fluid(maxWidth: 600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          ...DemoVideoFragment
          ...CallToActionFragment
        }
      }
      recipeCardsImage: file(
        relativePath: {
          eq: "solutions/know-your-food/40k+products.png"
        }
      ) {
        modal: childImageSharp {
          ...SharpImageModalFragment
        }
        childImageSharp {
          fluid(maxWidth: 600, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const pageData = markdownRemark.frontmatter

  return (
    <Page className="p-solutions p-solutions--caterers has-fingerPrints">
      <SEO title={pageData.title} description={pageData.description} />
      <Schema
        breadcrumbs={[
          {
            name: pageData.title,
            uri: pageData.path,
          },
        ]}
      />

      <LeadSpot
        header={pageData.lead.spot.header}
        text={pageData.lead.spot.text}
        imageFolder="know-your-food"
        imageWidth='800px'
      />

      <LeadStreamer
        id={pageData.lead.streamer.id}
        title={pageData.lead.streamer.title}
        image={pageData.lead.streamer.image}
        imageWidth={pageData.lead.streamer.imageWidth}
      />

      <section className="c-section c-section--postStreamer">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-postSteamer text-left mb-n5">
              <div className="row">
                <div
                  className={`col-12 col-lg-8 mb-5 d-flex flex-column justify-content-center order-md-2`}
                >
                  <h2 className="mb-4">
                    Choose from 40,000+ foodservice products
                  </h2>
                  <div className="c-postStreamer__text">
                    <p>
                      Erudus is the trusted source of data for tens of thousands
                      of foodservice products, entered directly by the
                      Manufacturer and updated as changes happen.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-lg-4 mb-5 text-center d-flex flex-column justify-content-center">
                    <Img
                      fluid={recipeCardsImage.childImageSharp.fluid}
                      title="Product Attributes"
                      // alt={blurb.title}
                      className="c-postStreamer__image img-fluid"
                    />
                </div>
              </div>
              <div className="row">
                <div
                  className={`col-8 mb-5 d-flex flex-column justify-content-center`}
                >
                  <h2 className="mb-4">Be clear on allergens</h2>
                  <p>
                    In foodservice, knowing about allergens is essential. That’s
                    why in Erudus, the 14 major food allergens are highlighted
                    with our clear and easy to read icons.
                  </p>
                  <p>The 14 major food allergens are</p>
                  <div className="c-postStreamer__text p-0 mb-3">
                    <ul className="m-0" style={{ listStyle: 'none' }}>
                      <li>Celery</li>
                      <li>Cereals containing Gluten</li>
                      <li>Crustaceans</li>
                      <li>Eggs</li>
                      <li>Fish</li>
                      <li>Lupin</li>
                      <li>Milk</li>
                      <li>Mustard</li>
                      <li>Molluscs</li>
                      <li>Nuts (Tree Nuts)</li>
                      <li>Peanuts</li>
                      <li>Sesame</li>
                      <li>Soya</li>
                      <li>Sulphur Dioxide (Sulphites)</li>
                    </ul>
                  </div>
                  <p>
                    It’s important to know the finer details, so with Erudus you
                    can also view the allergen information for sub-derivatives
                    of Tree Nuts and Cereals containing Gluten.
                  </p>
                  
                </div>
                <div className="col-4 mb-5 text-center d-flex flex-column justify-content-center">
                <iframe 
                style={{ border: 'none' }}
                width="100%" 
                height="90%" 
                src="https://ccfd854c.sibforms.com/serve/MUIEALshx61D6dJ6AAU6ofwkJ6hFSVR4CNBSYNiW2pk4fCE4vGRSzmzi_gCOQRKd4LH-ItdKF6SpMyxIFuJBFJaaNG3zCeJfAA6fgiNM3xi7cqXgRxXO5sX3ISxLCxFbSp1quDvWVcvxT5UgxEQLKkHjiTUBGqrh2d_jX0ieI6bADEGz3OpvXPzTyln5HMwmgjKR8kkAEGCFwbbT" 
                scrolling="no"
                >
                </iframe> 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id="benefits-for-your-customers"
        className={cn('c-section', styles.ntkSection)}
      >
        <div className="c-section__outer container">
          <div className="c-section__tag">What you need to know</div>
          <div className="c-section__inner">
            <div className="c-forCustBenefits row">
              <div className="c-forCustBenefits__header col-12">
                <img
                  class={cn('img-fluid', styles.ntkImage)}
                  src="/images/icons/solutions/know-your-food/What-is-Natashas-law.svg"
                  width="180"
                  height="182"
                />
                <h2 className="mb-4">{pageData.benefitsCustomers.title}</h2>
                <p className="text-left">
                  Natasha’s Law is a new piece of legislation that is officially
                  called the Food Information (Amendment) (England) Regulations
                  2019, and comes into effect from October 2021. It requires
                  that food businesses provide a full ingredient list and
                  allergen labelling on foods that have been prepackaged for
                  direct sale on the premises.
                </p>
                <p className="text-left">
                  Caterers will be most affected by this new legislation - any
                  food items that are made then prepackaged and sold onsite will
                  now have to be clearly labelled showing the name of the food
                  and the ingredients list, with any of the 14 major food
                  allergens required to be declared by law emphasised within
                  this ingredients list.
                </p>
                <p className="text-left">How Erudus can help you get ready…</p>
              </div>
              {pageData.benefitsCustomers.blurbs.map((blurb, i) => (
                <div key={blurb.title} className="col-12 col-md-4">
                  <img
                    src={`/images/icons/${blurb.icon}.png`}
                    srcSet={`/images/icons/${blurb.icon}.svg`}
                    alt={`${blurb.title} icon`}
                    className={cn('c-forCustBenefits__icon img-fluid', { 'mt-4 mb-4': isEven(i) } )}
                    style={{ height: 140 }}
                  />
                  <p className="c-forCustBenefits__title">{blurb.title}</p>
                  <p className="c-forCustBenefits__text">{blurb.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

    <section className="c-section c-section--forLeadSteamer">
     <div className="c-wave c-wave--top">
      <svg viewBox="0 0 500 150" preserveAspectRatio="none">
        <path
          style={{ stroke: 'none', fill: '#f4f4f4' }}
          d="M-0.84,110.03 C259.87,-110.03 333.80,252.13 500.84,76.47 L500.00,0.00 L0.00,0.00 Z"
        ></path>
      </svg>
     </div>

     <div className="c-section__outer container">
       <div className="c-section__inner">
         <div className="c-forLeadSteamer row">
           <div className="col-12 col-lg-6 col-xl-7">
             <h2 className="c-forLeadSteamer__header">The Erudus Recipe Builder - Everything you need to know about your recipe, in one place.</h2>
           </div>
           <div className="c-forLeadSteamer__icon col-12 col-lg-6 col-xl-5 text-center">
             <img
               className="img-fluid"
               width="223"
               src={`/images/icons/solutions/know-your-food/Allergen-Matrix.svg`}
             />
           </div>
         </div>
       </div>
     </div>

     <div className="c-wave c-wave--bottom">
       <svg viewBox="0 0 500 150" preserveAspectRatio="none">
         <path
           style={{ stroke: 'none', fill:'#ffffff' }}
           d="M-0.84,113.97 C271.16,40.95 365.97,59.70 500.84,81.41 L500.00,150.00 L0.00,150.00 Z"
         ></path>
       </svg>
     </div>
    </section>

      <section className="c-section c-section--postStreamer">
        <div className="c-section__outer container">
          <div className="c-section__inner">
            <div className="c-postSteamer text-left mb-n5">
              {pageData.section3.map((blurb, i) => (
                <div key={`section3-${i}`} className="row">
                  <div
                    className={cn(
                      'mb-5 col-12 col-md-6 d-flex flex-column justify-content-center',
                      { 'order-md-2': !isEven(i) }
                    )}
                  >
                    <h2 className="mb-4">{blurb.title}</h2>
                    <div className="c-postStreamer__text">
                      <p dangerouslySetInnerHTML={{ __html: blurb.text }} />
                    </div>
                  </div>
                  <div className="col-12 col-md-6 mb-5 text-center">
                    <ExpandableImage
                      imageData={blurb.image.modal}
                      bottomRight={isEven(i)}
                    >
                      <Img
                        fluid={blurb.image.childImageSharp.fluid}
                        title={blurb.title}
                        alt={blurb.title}
                        className="c-postStreamer__image img-fluid"
                      />
                    </ExpandableImage>
                  </div>
                </div>
              ))}
              <div className="row">
                <div
                  className={`col-12 col-lg-8 mb-5 d-flex flex-column justify-content-center`}
                >
                  <h2 className="mb-4">Product Attributes</h2>
                  <p>
                    Each recipe you make will automatically generate a Recipe
                    Card. They’re clear, concise and packed full of information.
                  </p>
                  <div className="c-postStreamer__text p-0 mb-3">
                    <ul className="m-0" style={{ listStyle: 'none' }}>
                      <li>Rolled up ingredients list</li>
                      <li>Optional quantity and costing information</li>
                      <li>Rolled up allergy data for the entire recipe</li>
                      <li>Reference intakes</li>
                      <li>Nutritional summary</li>
                      <li>Dietary information</li>
                      <li>Method & Recipe notes</li>
                    </ul>
                  </div>
                  <p>
                    All summarised onto a single, easy to download and print PDF
                    that can be popped into a kitchen or front of house folder
                    ready to answer (and impress) any enquiring customers.
                  </p>
                </div>
                <div className="col-12 col-lg-4 mb-5 text-center d-flex flex-column justify-content-center">
                    <Img
                      fluid={recipeCardsImage.childImageSharp.fluid}
                      title="Product Attributes"
                      alt="product-attributes"
                      className="c-postStreamer__image img-fluid"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <DemoVideo
        header={pageData.demoVideo.header}
        text={pageData.demoVideo.text}
        buttonText={pageData.demoVideo.buttonText}
        buttonLink={pageData.demoVideo.buttonLink}
        videoUrl={pageData.demoVideo.videoUrl}
      />

      <CallToAction ctaData={pageData.signUpCta} fill={`#f4f4f4`} />

      <Newsletter />
    </Page>
  )
}

export default KnowYourFoodPage